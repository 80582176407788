import React from "react"
import { Container, Row, Col  } from 'react-bootstrap'
import { graphql  } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Archives from "../components/archives"
import Article from "../components/article"

export default function allPostsByDate({ pageContext, data }) {
    const { posts  } = data.news
    const { uniqueDates, date, countPosts } = pageContext

    // Preprocess content
    const articles = posts.map(post => {
        return (
            <Article key={post.id} post={post} preview={true} />
        )
    })

    return (
        <Layout>
            <Seo title="News" />
            <Container>
                <Row className="justify-content-between">
                    <Col lg={8}>
                        <h3 className="display-4 mb-5">News from {date}</h3>
                        { articles }
                    </Col>
                    <Col lg={3}>
                        <Archives
                            countPosts={countPosts}
                            uniqueDates={ uniqueDates }
                        />
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export const pageQuery = graphql`
    query PostsByDateQuery($date: Date!) {
        news: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC  },
            filter: {fields: {yearMonth: {eq: $date}}},
        ) {
            posts: nodes {
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "LL")
                    title
                    location
                    image
                    imageSize
                }
                html
                excerpt(pruneLength: 500)
                id
            }
        }
    }
`
